export interface ValidationRule {
    required: boolean;
    type: string;
  }
  
  export const validationConfig: { [key: string]: ValidationRule } = {
    // Sessione VR
    Nome_formatore: {
      required: true,
      type: 'string',
    },
    Cognome_formatore: {
      required: true,
      type: 'string',
    },
    Data_inizio: {
      required: true,
      type: 'date',
    },
  };

  export const validationConfig2: { [key: string]: ValidationRule } = {
    // Utente
    Ragione_sociale: {
      required: true,
      type: 'string',
    },
    Email: {
      required: true,
      type: 'string',
    },
    Password: {
      required: true,
      type: 'string',
    },
    COD_Role: {
      required: false,
      type: 'date',
    },
  };