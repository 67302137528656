import * as Yup from 'yup'

export interface ICreateUser {
    Ragione_sociale: string
    Email: string
    Password: string
    COD_Role: number | null
}

export const createUserSchemas = Yup.object().shape({
    Ragione_sociale: Yup.string().required('La ragione sociale è obbligatoria').label('Ragione_sociale'),
    Email: Yup.string().email('Email non valida').required('Email obbligatoria').label('Email'),
    Password: Yup.string().min(8, 'La password deve essere di almeno 8 caratteri').required('Password obbligatoria').label('Password'),
    COD_Role: Yup.number().nullable().required('Il ruolo è obbligatorio').label('COD_Role'),
  });

export const inits: ICreateUser = {
    Ragione_sociale: '',
    Email: '',
    Password: '',
    COD_Role: null,
}
