/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from "react"
import {createPortal} from 'react-dom'
import {Formik, Form, FormikHelpers} from 'formik'
import { Field, ErrorMessage } from 'formik';
import {ICreateUser, createUserSchemas, inits} from './CreateUserChecker'
import Select from 'react-select'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../helpers'
import {createUser} from '../../../../api/usersApi'
import axios from '../../../../config/axiosConfig'

export interface IRoles {
  ID_Role: number
  Tipo: string
}

interface IOption {
  value: number
  label: string
}

type Props = {
  show: boolean;
  handleClose: () => void;
};

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateUser = ({ show, handleClose }: Props) => {
  const [initValues] = useState<ICreateUser>(inits)
  //const [nameCompany, setNameCompany] = useState<string>('');
  //const [email, setEmail] = useState<string >('');
  //const [password, setPassword] = useState<string >('');
  const [ruoli, setRuoli] = useState<IRoles[]>([]);
  const [selectedRole, setSelectedRole] = useState<IOption | null>(null);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  // Nuovo stato per gestire la visibilità della password
  const [showPassword, setShowPassword] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const roles = await axios.post<IRoles[]>('/api/users/listRoles');
        setRuoli(roles.data);
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [])

  const submitForm = async (values: ICreateUser, actions: FormikHelpers<ICreateUser>) => {
    try {
      const response = await createUser(values)
      if (response.status === 201) {
        setModalMessage(response.data);
      }
      setShowConfirmModal(true);
	  
	    // Reset dei campi del form
      actions.resetForm();

      // Reset del menù a tendina
      setSelectedRole(null);
    } catch (error: any) {
      if (error.response) {
        // La richiesta è stata effettuata e il server ha risposto con uno stato fuori dal range 2xx
        if (error.response.status === 400) {
          setModalMessage(error.response.data);
        } else {
          setModalMessage(error.response.data);
        }
      } else if (error.request) {
        // La richiesta è stata effettuata, ma non c'è stata alcuna risposta
        setModalMessage(error.response.data);
      } else {
        // Qualcosa è andato storto nella creazione della richiesta
        setModalMessage(error.response.data);
      }
    }
  };

  const closeConfirmModal = () => setShowConfirmModal(false);

  return createPortal(
    <>
    <Modal
      id="kt_modal_update_delivery_effective"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={handleClose}
    >
      <div className="modal-header">
        <h2>Nuovo utente</h2>
        {/* begin::Close */}
        <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={handleClose}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
        {/* end::Close */}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initValues}          // deve contenere { Ragione_sociale, Email, Password, COD_Role, ... }
          validationSchema={createUserSchemas}
          onSubmit={submitForm}
        >
          {({ values, setFieldValue }) => (
            <Form>
              {/* RAGIONE SOCIALE */}
              <div className='fv-row mb-10'>
                <label className='form-label required'>Ragione sociale</label>
                <Field
                  name='Ragione_sociale'
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                />
                <ErrorMessage name='Ragione_sociale' component='div' className='text-danger mt-2'/>
              </div>

              {/* EMAIL */}
              <div className='fv-row mb-10'>
                <label className='form-label required'>Email</label>
                <Field
                  name='Email'
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                />
                <ErrorMessage name='Email' component='div' className='text-danger mt-2'/>
              </div>

              {/* PASSWORD */}
              <div className='fv-row mb-10'>
                  <label className='form-label required'>Password</label>
                  <div className="input-group">
                    {/* type dinamico in base a showPassword */}
                    <Field
                      name='Password'
                      type={showPassword ? 'text' : 'password'}
                      className='form-control form-control-lg form-control-solid'
                    />
                    <span
                      className="input-group-text"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <KTSVG 
                        className="svg-icon-2" 
                        path={
                          showPassword 
                            ? '/media/icons/duotune/others/eye-regular.svg'
                            : '/media/icons/duotune/others/eye-slash-regular.svg'
                        }
                      />
                    </span>
                  </div>
                  <ErrorMessage name='Password' component='div' className='text-danger mt-2'/>
              </div>

              {/* RUOLO */}
              <div className='fv-row mb-10'>
                <label className='form-label required'>Ruolo</label>
                <Select
                  // Valore attuale di COD_Role (se presente in `values`), 
                  // convertito nel formato {value: x, label: y}
                  value={
                    values.COD_Role
                      ? {
                          value: values.COD_Role,
                          // cerco la label partendo dall'ID_Role
                          label: ruoli.find((r) => r.ID_Role === values.COD_Role)?.Tipo ?? 'Sconosciuto',
                        }
                      : null
                  }
                  // Quando l'utente seleziona un ruolo, aggiorno Formik
                  onChange={(selectedOption: IOption | null) => {
                    if (selectedOption) {
                      setFieldValue('COD_Role', selectedOption.value);
                    } else {
                      setFieldValue('COD_Role', null);
                    }
                  }}
                  // Elenco di opzioni
                  options={ruoli.map((ruolo) => ({
                    value: ruolo.ID_Role,
                    label: ruolo.Tipo,
                  }))}
                  className='form-select-solid form-select-lg fw-bold'
                  placeholder='Scegli ruolo'
                />
                <ErrorMessage name='COD_Role' component='div' className='text-danger mt-2'/>
              </div>

              <button type='submit' className='btn btn-lg btn-light-primary me-3'>Invio</button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
    <Modal
      id="kt_modal_confirm_assign"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={showConfirmModal}
      onHide={closeConfirmModal}
    >
      <div className="modal-header">
        <h2>Crea - Utente</h2>
        <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={closeConfirmModal}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
      </div>
      <div className="modal-body">
        <p>{modalMessage}</p>
      </div>
      <div className="modal-footer">
        <button className='btn btn-lg btn-light-primary me-3' onClick={closeConfirmModal}>Chiudi</button>
      </div>
    </Modal>
    </>,
    modalsRoot
  );
};

export { CreateUser };