import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import axios from '../../../../config/axiosConfig'
import { getUserByToken } from '../../../../api/api'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {WithChildren} from '../../../../_metronic/helpers'


type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = async () => {
    try {
      // Invia la richiesta di logout al server
      await axios.post('/api/auth/logout', {}, {
        headers: { Authorization: `Bearer ${auth?.api_token}` }
      });
      // Se la richiesta va a buon fine, rimuovi i dati di autenticazione dallo stato
      saveAuth(undefined);
      setCurrentUser(undefined);
    } catch (error) {
      // Gestisci eventuali errori qui
      console.error('Errore durante il logout', error);
    }
  };

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
  const requestUser = async (apiToken: string) => {
    try {
      //console.log("Eccalla1");
      if (!didRequest.current) {
        const tokenData = await getUserByToken(apiToken);
        console.log("user tok", tokenData)
        if (tokenData && tokenData[0] && tokenData[0].COD_User) {
          //console.log(tokenData[0].COD_User);
          //const userData = await getUserDetailsByToken(tokenData[0].COD_User);
          //if (userData) {
            setCurrentUser(tokenData[0]);
          //console.log(userData);
          //}
        }
      }
    } catch (error) {
      console.error(error);
      if (!didRequest.current) {
        logout();
      }
    } finally {
      setShowSplashScreen(false);
    }
  };

  if (auth && auth.api_token) {
    requestUser(auth.api_token);
  } else {
    setShowSplashScreen(false);
  }

  return () => {
	didRequest.current = true;
  };
  
  // eslint-disable-next-line
}, [auth]);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
