/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useMemo, useState, useEffect} from 'react'
import {saveAs} from 'file-saver'
import Papa from 'papaparse'
import {KTSVG, useDebounce} from '../../../helpers'
import {listSessionVR} from '../../../../api/sessionVRApi'
import {ViewDetailsSessionVR} from '../../modals/view-details-sessionvr/ViewDetailsSessionVR'
import {EditSessionVRAperta} from '../../modals/edit-sessionvr/EditSessionVRAperta'
import {DeleteSessionVR} from '../../modals/delete-sessionvr/DeleteSessionVR'
import {GenerateReport} from '../../modals/generate-report/GenerateReport'
import {EndSessionVRAndGenerateReport} from '../../modals/end-sessionvr-and-generate-report/EndSessionVRAndGenerateReport'
import {detailsReport} from '../../../../api/sessionVRApi'
import {UsersListSearchComponent} from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {UsersListPagination} from '../../../../app/modules/apps/user-management/users-list/components/pagination/UsersListPagination'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import './StoricoSessioniVRWidget.css';
import caskyBase64 from './caskyBase64';

import dayjs from 'dayjs';
import 'dayjs/locale/it';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
dayjs.locale('it');

interface MyData {
  ID_Sessione_VR: string
  Nome_formatore: string
  Cognome_formatore: string
  Numero_partecipanti: number
  Data_inizio: string
  Terminata: boolean
  Stato: string
}

type SortConfig = {
  key: keyof MyData;
  direction: 'asc' | 'desc';
};

type Props = {
  className: string
}

const StoricoSessioniVRWidget: React.FC<Props> = ({className}) => {
  const {currentUser, logout} = useAuth()
  const user = Array.isArray(currentUser) && currentUser.length > 0 ? currentUser[0] : currentUser;

  const [data, setData] = useState<MyData[]>([]);
  const [filteredData, setFilteredData] = useState<MyData[]>([]); // New state for filtered data
  const [searchTerm, setSearchTerm] = useState<string>(''); // New state for search term
  const debouncedSearchTerm = useDebounce(searchTerm, 150); // Debounce search term
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: 'Data_inizio', direction: 'desc' });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Numero di elementi per pagina

  const start = (currentPage - 1) * itemsPerPage;
  const end = start + itemsPerPage;

  const [showModal, setShowModal] = useState(false);
  const [showEditModal2, setShowEditModal2] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showGenerateReportModal, setShowGenerateReportModal] = useState(false);
  const [showEndSessionVRAndGenerateReportModal, setShowEndSessionVRAndGenerateReportModal] = useState(false);
  const [sessionvrStatus, setSessionvrStatus] = useState<string>('Aperta');

  const [selectedRecordId, setSelectedRecordId] = useState<string | null>(null);
  const [selectedTerminata, setSelectedTerminata] = useState<boolean>(false);

  const sortedData = useMemo(() => {
    return [...filteredData].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [filteredData, sortConfig]);

  const handleRowClick = (action: string, recordId: string, terminata: boolean) => {
    setSelectedRecordId(recordId);
    setSelectedTerminata(terminata);
    if(action === 'view-details') {
      setShowModal(true);
    } else if(action === 'edit-sessionvr' && sessionvrStatus === 'Aperta') {
      setShowEditModal2(true);
    } else if(action === 'delete-sessionvr') {
      setShowDeleteModal(true);
    } else if(action === 'end-sessionvr-and-generate-report' && sessionvrStatus === 'Aperta') {
      setShowEndSessionVRAndGenerateReportModal(true);
    } else if(action === 'generate-report' && sessionvrStatus === 'Chiusa') {
      setShowGenerateReportModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseEditModal2 = () => {
    setShowEditModal2(false);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleCloseGenerateReportModal = () => {
    setShowGenerateReportModal(false);
  };

  const handleCloseEndSessionVRAndGenerateReportModal = () => {
    setShowEndSessionVRAndGenerateReportModal(false);
  };

  const [stateCounts, setStateCounts] = useState<{ [key: string]: number }>({});
  //const [allData, setAllData] = useState<MyData[]>([]);

  const fetchData = async () => {
    try {
      let response = await listSessionVR(user.COD_User);

      for (let i = 0; i < response.length; i++) {
        let Data_inizio_US = dayjs(response[i].Data_inizio);
        response[i].Data_inizio = Data_inizio_US.format('LL');

        if(response[i].Terminata)
        {
          response[i].Stato = "Chiusa"
        }
        else
        {
          response[i].Stato = "Aperta"
        }
      }
  
      // Calcola il conteggio per ogni stato
      const counts: { [key: string]: number } = {};
      response.forEach((sessionVR: MyData) => {
        counts[sessionVR.Stato] = (counts[sessionVR.Stato] || 0) + 1;
      });
  
      // Imposta i conteggi
      setStateCounts(counts);
  
      if(sessionvrStatus !== null) {
        response = response.filter((sessionVR: MyData) => sessionVR.Stato === sessionvrStatus);
      }
  
      // Imposta i dati
      setData(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [sessionvrStatus]);

  // useEffect per gestire la barra di ricerca
  useEffect(() => {
    if (debouncedSearchTerm) {
      const lowerCasedSearchTerm = debouncedSearchTerm.toLowerCase();
      const filtered = data.filter(order => {
        return Object.values(order).some(val => {
          if (val !== null && typeof val === 'string') {
            return val.toLowerCase().includes(lowerCasedSearchTerm);
          }
          return false;
        });
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [debouncedSearchTerm, data]);

  // Nuova funzione per gestire il cambio dello stato dell'ordine
  const handleOrderStatusChange = (status: string) => {
    setCurrentPage(1);
    setSessionvrStatus(status);
  };

  const changeSort = (key: keyof MyData) => {
    setSortConfig(currentSortConfig => {
      if (currentSortConfig.key === key) {
        return { key, direction: currentSortConfig.direction === 'asc' ? 'desc' : 'asc' };
      }
      return { key, direction: 'asc' };
    });
  };

  const exportDataToCSV = () => {
    const csv = Papa.unparse(filteredData); // Usa filteredData qui
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'Data.csv');
  };

  /* Generatore report PDF */
  const generatePDF = async (recordId: string, terminata: boolean) => {
    try {
      const inputReport = { sessionvrID: recordId, terminata: terminata };

      // Chiamata API per ottenere i dati
      const { theoreticalResults, practicalResults } = await detailsReport(inputReport);

      // Verifica se ci sono risultati teorici
      const hasTheoreticalResults = theoreticalResults && theoreticalResults.length > 0;

      // Raggruppa i risultati per simulazione
      const groupedResults: any = {};
      theoreticalResults.forEach((result: any) => {
        if (!groupedResults[result.Simulazione]) {
          groupedResults[result.Simulazione] = { theoretical: [], practical: [] };
        }
        groupedResults[result.Simulazione].theoretical.push(result);
      });

      practicalResults.forEach((result: any) => {
        if (!groupedResults[result.Simulazione]) {
          groupedResults[result.Simulazione] = { theoretical: [], practical: [] };
        }
        groupedResults[result.Simulazione].practical.push(result);
      });

      const doc = new jsPDF();
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const marginLeft = 10;
      const marginRight = 10;
      const maxWidth = pageWidth - marginLeft - marginRight;
      const lineHeight = 10;
      const spaceBetweenQuestions = 5;
      const spaceAfterQuestion = 3;
      let yPosition = 10;
      let hasContent = false;

      const logo = 'data:image/png;base64,' + caskyBase64;
      const logoWidth = 20;
      const logoHeight = 20;
      const logoX = pageWidth - logoWidth - 10;
      const logoY = pageHeight - logoHeight - 10;

      const addFooter = () => {
        doc.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight);
        hasContent = true;
      };

      const checkAndAddPage = (neededSpace: any) => {
        if (yPosition + neededSpace > pageHeight - 10) {
          if (yPosition > 10) {
            doc.addPage();
            yPosition = 10;
          }
        }
      };

      const handleTextPlacement = (text: any, xPosition: any, maxWidth: any, fontSize: any, color: any, isQuestion = false, extraSpaceNeeded = 0) => {
        doc.setFontSize(fontSize);
        doc.setTextColor(color.r, color.g, color.b);
        const lines = doc.splitTextToSize(text, maxWidth);

        const neededSpace = lines.length * lineHeight + (isQuestion ? spaceAfterQuestion : 0) + extraSpaceNeeded;
        checkAndAddPage(neededSpace);

        lines.forEach((line: any) => {
          doc.text(line, xPosition, yPosition);
          yPosition += lineHeight;
        });

        if (isQuestion) {
          yPosition += spaceAfterQuestion;
        }
        addFooter();
        hasContent = true;
      };

      // Se ci sono risultati teorici, aggiungi la parte teorica
      if (hasTheoreticalResults) {
        Object.keys(groupedResults).forEach(simulazione => {
          const results = groupedResults[simulazione];

          results.theoretical.forEach((result: any, index: any) => {
            if (index > 0) {
              doc.addPage();
              yPosition = 10;
            }

            let extraSpaceForAnswers = 0;
            let correctAnswersCount = 0;
            let totalQuestionsCount = result.RisultatoQuizTeoria.domande.length;

            result.RisultatoQuizTeoria.domande.forEach((domanda: any) => {
              const tutteFalse = domanda.risposte.every((risposta: any) => !risposta.sceltaUtente);
              if (tutteFalse) {
                totalQuestionsCount--;
                return;
              }

              domanda.risposte.forEach((risposta: any) => {
                const linesForAnswer = doc.splitTextToSize(`- ${risposta.testo}`, maxWidth - 5);
                extraSpaceForAnswers += linesForAnswer.length * lineHeight;
              });
              extraSpaceForAnswers += spaceBetweenQuestions;

              const userAnswer = domanda.risposte.filter((risposta: any) => risposta.sceltaUtente);
              if (userAnswer.length === 1 && userAnswer[0].corretta) {
                correctAnswersCount++;
              }
            });

            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0);
            doc.text(`Punteggio ${correctAnswersCount}/${totalQuestionsCount}`, pageWidth - marginRight, yPosition, { align: 'right' });
            addFooter();

            handleTextPlacement(
              `${result.Nome} ${result.Cognome} - ${result.Simulazione} - ${result.Livello}`,
              marginLeft,
              maxWidth,
              18,
              { r: 0, g: 0, b: 0 },
              true,
              extraSpaceForAnswers
            );

            result.RisultatoQuizTeoria.domande.forEach((domanda: any, index: any) => {
              if (domanda.risposte.every((risposta: any) => !risposta.sceltaUtente)) {
                return;
              }

              const newMaxWidth = maxWidth - 30;
              const linesForQuestion = doc.splitTextToSize(domanda.testo, newMaxWidth);
              let neededSpace = linesForQuestion.length * lineHeight;

              domanda.risposte.forEach((risposta: any) => {
                const linesForAnswer = doc.splitTextToSize(`- ${risposta.testo}`, newMaxWidth - 5);
                neededSpace += linesForAnswer.length * lineHeight + spaceBetweenQuestions;
              });

              if (yPosition + neededSpace > pageHeight - 10) {
                doc.addPage();
                yPosition = 10;
              }

              if (index > 0) yPosition += spaceBetweenQuestions;

              const isCorrect = domanda.risposte.some((risposta: any) => risposta.sceltaUtente && risposta.corretta);
              const symbol = isCorrect ? ' V' : ' X';
              const color = isCorrect ? { r: 0, g: 128, b: 0 } : { r: 255, g: 0, b: 0 };

              handleTextPlacement(
                domanda.testo,
                marginLeft,
                newMaxWidth,
                12,
                { r: 0, g: 0, b: 0 },
                true
              );

              yPosition -= 6;
              doc.setTextColor(color.r, color.g, color.b);
              doc.text(symbol, marginLeft + maxWidth - 10, yPosition);
              yPosition += lineHeight;

              domanda.risposte.forEach((risposta: any) => {
                let rispostaColor = { r: 0, g: 0, b: 0 };
                if (risposta.sceltaUtente && risposta.corretta) {
                  rispostaColor = { r: 0, g: 128, b: 0 };
                } else if (risposta.sceltaUtente && !risposta.corretta) {
                  rispostaColor = { r: 255, g: 0, b: 0 };
                } else if (!risposta.sceltaUtente && risposta.corretta) {
                  rispostaColor = { r: 0, g: 128, b: 0 };
                }

                handleTextPlacement(
                  `- ${risposta.testo}`,
                  marginLeft + 5,
                  maxWidth - 5,
                  12,
                  rispostaColor
                );
              });
            });
          });
        });

        // Aggiungi una nuova pagina se ci sono risultati pratici
        if (practicalResults.length > 0) {
          doc.addPage();
          yPosition = 10;
        }
      }

      if (practicalResults.length > 0) {
        yPosition = 10;

        practicalResults.forEach((result: any) => {
          handleTextPlacement(
            `${result.Nome} ${result.Cognome} - ${result.Simulazione} - Livello: ${result.Livello} - Punteggio ${result.Punteggio}`,
            marginLeft,
            maxWidth,
            14,
            { r: 0, g: 0, b: 0 }
          );
        });
      }

      if (!hasContent) {
        doc.deletePage(1);
      }

      doc.save('report-sessione-vr.pdf');
    } catch (error) {
      console.error("Errore nella generazione del PDF:", error);
    }
};

  const onGenerateReport = async (recordId:string, terminata:boolean) => {
    generatePDF(recordId, terminata);
  };
  
  return (
    <div className={`card ${className}`}>
      {/* begin::Barra ricerca */}
      <div className='card-header border-0 pt-6'>
      <UsersListSearchComponent setParentSearchTerm={setSearchTerm} />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Export */}
        <button type='button' className='btn btn-light-primary me-3 no-margin' onClick={exportDataToCSV}>
          <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
          Export
        </button>
        {/* end::Export */}
      </div>
      {/* end::Card toolbar */}
      </div>
      {/* end::Barra ricerca */}
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Storico Sessioni VR</span>
          <span className='text-muted mt-1 fw-semibold fs-7'></span>
        </h3>
        <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-attivo fw-bold px-4 me-1 ${sessionvrStatus === 'Aperta' ? 'active' : ''}`}
                onClick={() => handleOrderStatusChange('Aperta')}
              >
                Aperta ({stateCounts['Aperta'] || 0})
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-ricevuto fw-bold px-4 me-1 ${sessionvrStatus === 'Chiusa' ? 'active' : ''}`}
                onClick={() => handleOrderStatusChange('Chiusa')}
              >
                Chiusa ({stateCounts['Chiusa'] || 0})
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px th-padding text-muted fw-bold text-head' onClick={() => changeSort('ID_Sessione_VR')}>Sessione VR</th>
                    <th className='p-0 min-w-150px th-padding text-muted fw-bold text-head' onClick={() => changeSort('Nome_formatore')}>Nome formatore</th>
                    <th className='p-0 min-w-150px th-padding text-muted fw-bold text-head' onClick={() => changeSort('Cognome_formatore')}>Cognome formatore</th>
                    <th className='p-0 min-w-150px th-padding text-muted fw-bold text-head' onClick={() => changeSort('Numero_partecipanti')}>N° partecipanti</th>
                    <th className='p-0 min-w-150px th-padding text-muted fw-bold text-head' onClick={() => changeSort('Data_inizio')}>Data inizio</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                {sortedData.slice(start, end).map(item => (
                    <tr key={item.ID_Sessione_VR}>
                      <td></td>
                      <td style={{ fontSize: "1.15rem" }} className='fw-semibold'>{item.ID_Sessione_VR}</td>
                      <td className='text-body fw-semibold'>{item.Nome_formatore}</td>
                      <td className='text-body fw-semibold'>{item.Cognome_formatore}</td>
                      <td className='text-body fw-semibold'>{item.Numero_partecipanti}</td>
                      <td className='text-body fw-semibold'>{item.Data_inizio}</td>
                      {/*<td className='text-end'>
                        <span className={`badge ${item.Stato_Ordine === "Ricevuto" ? "badge-light-ricevuto" : ""} ${item.Stato_Ordine === "In lavorazione" ? "badge-light-inlavorazione" : ""} 
                        ${item.Stato_Ordine === "Pronto" ? "badge-light-pronto" : ""} ${item.Stato_Ordine === "Spedito" ? "badge-light-spedito" : ""} ${item.Stato_Ordine === "Attivo" ? "badge-light-attivo" : ""} 
                        ${item.Stato_Ordine === "In scadenza" ? "badge-light-inscadenza" : ""} ${item.Stato_Ordine === "Scaduto" ? "badge-light-scaduto" : ""} 
                        ${item.Stato_Ordine === "Inattivo" ? "badge-light-inattivo" : ""}`}>
                          {item.Stato_Ordine}
                        </span>
                      </td>*/}
                      <td className='text-end'>
                        <div className='btn-group'>
                          <button
                            type='button'
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                            data-bs-toggle='dropdown'
                            aria-expanded='false'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen052.svg'
                              className='svg-icon-2'
                            />
                          </button>
                          <ul className='dropdown-menu custom-dropdown-menu'>
                            <li className='menu-item px-3'>
                              <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('view-details', item.ID_Sessione_VR, item.Terminata)}>
                                Dettagli
                              </a>
                            </li>               
                            {item.Stato === 'Aperta' && (
                              <li className='menu-item px-3'>
                                <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('edit-sessionvr', item.ID_Sessione_VR, item.Terminata)}>
                                  Modifica
                                </a>
                              </li>
                            )}
                            <li className='menu-item px-3'>
                              <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('delete-sessionvr', item.ID_Sessione_VR, item.Terminata)}>
                                Elimina
                              </a>
                            </li>
                            {item.Stato === 'Aperta' && (
                              <li className='menu-item px-3'>
                                <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('end-sessionvr-and-generate-report', item.ID_Sessione_VR, item.Terminata)}>
                                  Termina e Genera report
                                </a>
                              </li>
                            )}
                            {item.Stato === 'Chiusa' && (
                              <li className='menu-item px-3'>
                                <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('generate-report', item.ID_Sessione_VR, item.Terminata)}>
                                  Genera report
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
            <UsersListPagination
              totalItems={filteredData.length}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
      {selectedRecordId !== null && (
        <>
        <ViewDetailsSessionVR show={showModal} handleClose={handleCloseModal} recordId={selectedRecordId}/>
        <EditSessionVRAperta show={showEditModal2} handleClose={handleCloseEditModal2} recordId={selectedRecordId}/>
        <DeleteSessionVR show={showDeleteModal} handleClose={handleCloseDeleteModal} recordId={selectedRecordId}/>
        <GenerateReport show={showGenerateReportModal} handleClose={handleCloseGenerateReportModal} recordId={selectedRecordId} terminata={selectedTerminata} onGenerateReport={onGenerateReport} />
        <EndSessionVRAndGenerateReport show={showEndSessionVRAndGenerateReportModal} handleClose={handleCloseEndSessionVRAndGenerateReportModal} recordId={selectedRecordId} terminata={selectedTerminata} onGenerateReport={onGenerateReport} />
        </>
      )}
    </div>
  )
}

export {StoricoSessioniVRWidget}
