import axios from '../config/axiosConfig';


/* Elenco dei risultati ottenuti nelle didattiche delle varie simulazioni */
export const getSimulationResultsQuiz = async (idUser, selectedPeriod, selectedSimulation) => {
  try {
    const response = await axios.post('/api/homepage/listQuizResults', { idUser, selectedPeriod, selectedSimulation });
    return response.data;
  } catch (error) {
    console.error('Error fetching quiz results: ', error);
    throw error;
  }
};

/* Elenco dei risultati ottenuti nelle segnalazioni delle varie simulazioni */
export const getSimulationResultsReporting = async (idUser, selectedPeriod, selectedSimulation) => {
  try {
    const response = await axios.post('/api/homepage/listReportResults', { idUser, selectedPeriod, selectedSimulation });
    return response.data;
  } catch (error) {
    console.error('Error fetching reporting results: ', error);
    throw error;
  }
};

// Lista delle simulazioni delle quali si è fatto almeno una volta la didattica
export const getSimulationsWithQuizResult = async (idUser) => {
  try {
    const response = await axios.get(`/api/homepage/listSimulationsWithQuizResult/${idUser}`);
    return response.data;
  } catch (error) {
    console.error('Errore nel recuperare la lista delle simulazioni con quiz fatto:', error);
    throw error;
  }
};

// Lista delle simulazioni delle quali si è fatto almeno una volta la didattica
export const getSimulationsWithReportResult = async (idUser) => {
  try {
    const response = await axios.get(`/api/homepage/listSimulationsWithReportResult/${idUser}`);
    return response.data;
  } catch (error) {
    console.error('Errore nel recuperare la lista delle simulazioni con segnalazioni fatto:', error);
    throw error;
  }
};
